.label {
  font-size: 20px;
  font-weight: 500;
}

.checksContainer {
  display: flex;
  justify-content: space-between;
}

.termsContainer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
}

.error {
  height: 15px;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}
