.rowCell {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover .hoveredButton {
    visibility: visible;
  }

  &:hover .phoneLink {
    visibility: visible;
  }
}

.coloredSelect {
  display: flex;
  height: 100%;
  align-items: center;
}
