.dataGrid {
  .MuiDataGrid-columnHeader {
    background-color: #f5f5f5;
    text-align: center;
    color: #637381;
    border-right: 1px solid lightgray;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }

  & ::-webkit-scrollbar {
    width: 6px;
    height: 13px;
  }

  & ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}
