.container {
  display: flex;

  .formContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //box-shadow: ;
  }
  .form {
    max-width: 60%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
  }

  .imageContainer {
    width: 60%;
    background-image: url('../../public/assets/illustrations/loginBackground.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
}
